$primary: #22aaff;
$danger: #ff7722;
$warning: #ffb800;
$red: #ff0000;
$success: #40ae40;
$purpele: #5f50fd;

$theme-colors: (
  "primary": $primary,
  "danger": $danger,
  "warning": $warning,
  "red": $red,
  "success": $success,
  "purple": $purpele
);

$color-terminated: #22aaff40;
$color-annual-client: #ff4d4f40;
$color-annual-conductor: #ff4d4f40;
$color-no-conductor: #8d8d8d40;
$color-on-going: #b3f69c;

@mixin badge($bg-color, $text-color) {
  display: inline-block;
  padding: 0.5em 1em;
  border: 0;
  outline: none;
  border-radius: 5px;
  background-color: $bg-color;
  color: $text-color;
  font-size: 1em;
  text-align: center;
  margin: 0.5em;
}

.badge-completed {
  @include badge($color-terminated, #22aaff);
}

.badge-cancel {
  @include badge($color-annual-client, #ff4d4f);
}

.badge-danger {
  @include badge($color-annual-conductor, #ff4d4f);
}

.badge-neutral {
  @include badge($color-no-conductor, #6a6a6a);
}

.badge-success {
  @include badge($color-on-going, #00bc1e);
}
